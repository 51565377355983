import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import Page404 from '../components/Layout/Page404/Page404';
import Instagram from '../components/Layout/Instagram/Instagram';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Layout whitwHeader={'active'}>
      <Seo title={t('404: Not found')} />
      <Page404 />
      <Instagram />
    </Layout>
  );
};

export default NotFoundPage;
