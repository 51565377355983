import React from 'react';
import useLanguage from '../../Hooks/useLanguage';

import Ilustration404 from '../../../svg/ilustration_404.svg';

const Page404 = () => {
  const langToggle = useLanguage;

  return (
    <section className="page-404">
      <section className="content-center">
        <Ilustration404 />
        <h1 className="h1">
          {langToggle(
            'Нажаль сторінку не знайдено !',
            'К сожалению страница не найдена !',
            'Sorry, the page was not found!'
          )}
        </h1>
      </section>
    </section>
  );
};

export default Page404;
